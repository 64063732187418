import React, { useContext, useState } from "react";
import {
  IonContent,
  IonDatetime,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonList,
  IonButton,
  IonToast,
} from "@ionic/react";
import { formatISO } from "date-fns";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { useForm } from "react-hook-form";
import { NewExpenseProps } from "../../models";
import { Input } from "../../shared/components/Input";
import { NumberInput } from "../../shared/components/NumberInput";
import { Select } from "../../shared/components/Select";
import { Datetime } from "../../shared/components/Datetime";
import { AuthContext } from "../../shared/context/auth-context";
import "../../theme/Main.css";

export const LogPage: React.FC<NewExpenseProps> = (props): JSX.Element => {
  const auth = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();
  const { sendRequest } = useHttpClient();
  const [showToast, setShowToast] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/expense",
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      ).then((responseData) => {
        // Clears form
        const logForm = document.getElementById("log-form") as HTMLFormElement;
        if (logForm) logForm.reset();

        // Resets datetime selector
        // setValue("dt", formatISO(new Date()), {
        //   shouldValidate: true,
        //   shouldDirty: true,
        // });

        setShowToast(true);
        props.setNewExpense(true);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Log</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Log</IonTitle>
          </IonToolbar>
        </IonHeader>
        {auth.isLoggedIn && (
          <IonList>
            <form
              id="log-form"
              className="log-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Input name="vendor" register={register}>
                Vendor
              </Input>
              <Input name="product" register={register}>
                Product
              </Input>
              <NumberInput name="cost" register={register}>
                Cost
              </NumberInput>
              <Select register={register} setValue={setValue}></Select>
              <Datetime control={control}></Datetime>
              <IonButton expand="block" type="submit">
                Log
              </IonButton>

              <IonToast
                isOpen={showToast}
                onDidDismiss={() => setShowToast(false)}
                message="Expense logged"
                duration={1500}
                position="top"
              />
            </form>
          </IonList>
        )}
        {!auth.isLoggedIn && (
          <div className="container">
            <strong>Please log in</strong>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};
