import React, { useContext, useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonButton,
  IonToast,
} from "@ionic/react";
import { useHttpClient } from "../shared/hooks/http-hook";
import { useForm } from "react-hook-form";
import { AuthContext } from "../shared/context/auth-context";

export const SettingsPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showToast, setShowToast] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const onSubmit = async (data: any) => {
    if (auth.isLoggedIn) {
      auth.logout();
      return;
    }

    try {
      setErrorState(false);
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/login",
        "POST",
        JSON.stringify(data),
        { "Content-Type": "application/json" }
      ).then((responseData) => {
        if (typeof responseData.userId !== "undefined")
          auth.login(responseData.userId, responseData.token);
        else setShowToast(true);
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
            {auth.isLoggedIn && (
              <IonButton expand="block" type="submit">
                Logout
              </IonButton>
            )}
            {!auth.isLoggedIn && (
              <React.Fragment>
                <IonItem>
                  <IonLabel position="floating">Password</IonLabel>
                  <IonInput
                    type="password"
                    {...register("password")}
                  ></IonInput>
                </IonItem>

                <IonButton expand="block" type="submit">
                  Login
                </IonButton>
                <IonToast
                  isOpen={showToast}
                  onDidDismiss={() => setShowToast(false)}
                  message="Invalid credentials"
                  duration={1500}
                  position="middle"
                />
              </React.Fragment>
            )}
          </form>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
