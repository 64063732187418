import {
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonModal,
} from "@ionic/react";
import React from "react";
import { formatISO } from "date-fns";
import { Control, Controller, FieldValues } from "react-hook-form";

interface DatetimeProps {
  control: Control<FieldValues, any>;
}

export const Datetime: React.FC<DatetimeProps> = ({ control }): JSX.Element => {
  return (
    <Controller
      render={({ field: { onChange } }) => (
        <IonItem>
          <IonDatetimeButton datetime="datetime"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetime"
              onIonChange={onChange}
              preferWheel={true}
            ></IonDatetime>
          </IonModal>
        </IonItem>
      )}
      control={control}
      defaultValue={formatISO(new Date())}
      name="dt"
      rules={{ required: true }}
    />
  );
};
