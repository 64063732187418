import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { add, albums, logIn } from "ionicons/icons";
import { SettingsPage } from "./settings/SettingsPage";
import { LogPage } from "./log/pages/LogPage";
import { HistoryPage } from "./history/pages/HistoryPage";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

setupIonicReact();

export const App: React.FC = () => {
  const { token, login, logout, userId } = useAuth();
  const [newExpense, setNewExpense] = useState(false);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        login: login,
        logout: logout,
      }}
    >
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/log">
                <LogPage
                  newExpense={newExpense}
                  setNewExpense={setNewExpense}
                />
              </Route>
              <Route exact path="/history">
                <HistoryPage
                  newExpense={newExpense}
                  setNewExpense={setNewExpense}
                />
              </Route>
              <Route path="/settings">
                <SettingsPage />
              </Route>
              <Route exact path="/">
                <Redirect to="/log" />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="log" href="/log">
                <IonIcon icon={add} />
                <IonLabel>Log</IonLabel>
              </IonTabButton>
              <IonTabButton tab="history" href="/history">
                <IonIcon icon={albums} />
                <IonLabel>History</IonLabel>
              </IonTabButton>
              <IonTabButton tab="settings" href="/settings">
                <IonIcon icon={logIn} />
                <IonLabel>Settings</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </AuthContext.Provider>
  );
};
