import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);

  const login = useCallback((uid, token) => {
    setToken(token);
    setUserId(uid);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        token: token,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (!userData) return;
    const storedData = JSON.parse(userData);
    if (storedData && storedData.token) {
      login(storedData.userId, storedData.token);
    }
    return () => {};
  }, [login]);

  return { token, login, logout, userId };
};
