import { Expense } from "../../models";
import { IonItem, IonLabel } from "@ionic/react";
import { format } from "date-fns";

interface ExpenseProps {
  expense: Expense;
}

export const HistoryItem: React.FC<ExpenseProps> = ({
  expense,
}): JSX.Element => {
  const dt = format(new Date(expense.dt), "d MMM h:mmaaa");
  return (
    <IonItem>
      <IonLabel>
        <h2>
          <b>{expense.vendor}</b>
        </h2>
        <p className="ion-text-wrap">{expense.product}</p>
      </IonLabel>
      <p className="ion-text-right">
        <b>${expense.cost?.toFixed(2)}</b>
        <br />
        {dt}
      </p>
    </IonItem>
  );
};
