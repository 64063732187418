import {
  IonContent,
  IonHeader,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
} from "@ionic/react";
import React, { useEffect, useContext, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Expense, toExpense, NewExpenseProps } from "../../models";
import { HistoryList } from "../components/HistoryList";
import { AuthContext } from "../../shared/context/auth-context";
import "../../theme/Main.css";

export const HistoryPage: React.FC<NewExpenseProps> = (props): JSX.Element => {
  const auth = useContext(AuthContext);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const { sendRequest } = useHttpClient();

  function getHistory(event?: CustomEvent<RefresherEventDetail>) {
    if (!auth.isLoggedIn) return;
    sendRequest(process.env.REACT_APP_BACKEND_URL + "/expense", "GET", null, {
      Authorization: "Bearer " + auth.token,
    })
      .then((responseData) => {
        setExpenses(responseData.expenses.map(toExpense));
        props.setNewExpense(false);
        if (event !== undefined) event.detail.complete();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getHistory();
    return () => {};
  }, [auth, props.newExpense]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>History</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {/* <IonContent>
          <IonRefresher slot="fixed" onIonRefresh={getHistory}>
            <IonRefresherContent> */}
              {auth.isLoggedIn && (
                <HistoryList expenses={expenses}></HistoryList>
              )}
              {!auth.isLoggedIn && (
                <div className="container">
                  <strong>Please log in</strong>
                </div>
              )}
            {/* </IonRefresherContent>
          </IonRefresher>
        </IonContent> */}
      </IonContent>
    </IonPage>
  );
};
