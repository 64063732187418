import { IonItem, IonSelect, IonSelectOption } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { FieldValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { AuthContext } from "../context/auth-context";
import { useHttpClient } from "../hooks/http-hook";
import { Category, toCategory } from "../../models";

interface SelectProps {
  register: UseFormRegister<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

export const Select: React.FC<SelectProps> = ({
  register,
  setValue,
}): JSX.Element => {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [category, setCategory] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);
  const [subcategory, setSubcategory] = useState<number>(0);
  const [subcategories, setSubcategories] = useState<Category[]>([]);

  useEffect(() => {
    sendRequest(process.env.REACT_APP_BACKEND_URL + "/category", "GET", null, {
      Authorization: "Bearer " + auth.token,
    })
      .then((responseData) => {
        setCategories(responseData.categories.map(toCategory));
        setCategory(1);
      })
      .catch((error) => {
        console.error(error);
      });
    return () => {};
  }, []);

  const getSubcategories = (e: any) => {
    const categoryId = e.detail.value;
    setCategory(categoryId);
    sendRequest(
      process.env.REACT_APP_BACKEND_URL + "/category/" + categoryId,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    )
      .then((responseData) => {
        let minSubcategory = -1;
        setSubcategories(
          responseData.categories.map((category: any) => {
            minSubcategory =
              minSubcategory === -1 ? category.category_id : minSubcategory;
            return toCategory(category);
          })
        );
        setSubcategory(minSubcategory);
        setValue("subcategory", minSubcategory);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <IonItem>
        <IonSelect
          interface="popover"
          placeholder="Category"
          value={category}
          onIonChange={getSubcategories}
          {...register("category")}
        >
          {categories.map((category) => (
            <IonSelectOption key={category.key} value={category.key}>
              {category.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>

      <IonItem>
        <IonSelect
          interface="popover"
          placeholder="Subcategory"
          value={subcategory}
          onIonChange={(e) => {
            setSubcategory(e.detail.value);
          }}
          {...register("subcategory")}
        >
          {subcategories.map((subcategory) => (
            <IonSelectOption key={subcategory.key} value={subcategory.key}>
              {subcategory.name}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </React.Fragment>
  );
};
