import { Expense } from "../../models";
import { IonList } from "@ionic/react";
import { HistoryItem } from "./HistoryItem";
import React from "react";

interface ExpenseList {
  expenses: Expense[];
}

export const HistoryList: React.FC<ExpenseList> = ({
  expenses,
}): JSX.Element => {
  return (
    <IonList>
      {expenses.map((expense) => (
        <HistoryItem key={expense.key} expense={expense}></HistoryItem>
      ))}
    </IonList>
  );
};
