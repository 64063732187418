import { IonInput, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { FieldValues, UseFormRegister } from "react-hook-form";

interface InputProps {
  name: string;
  register: UseFormRegister<FieldValues>;
}

export const Input: React.FC<InputProps> = ({
  name,
  register,
  children,
}): JSX.Element => {
  return (
    <IonItem>
      <IonLabel position="floating">{children}</IonLabel>
      <IonInput
        autocapitalize="words"
        autocomplete="on"
        {...register(name)}
      ></IonInput>
    </IonItem>
  );
};
