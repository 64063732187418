import { Dispatch, SetStateAction } from "react";

export interface Expense {
  key: string;
  product: string;
  vendor: string;
  cost: number;
  dt: string;
}

export function toExpense(x: any): Expense {
  return {
    key: x.key,
    product: x.product,
    vendor: x.vendor,
    cost: x.cost,
    dt: x.dt.value
  };
}

export interface Category {
  key: number;
  name: string;
}

export function toCategory(x: any): Category {
  return {
    key: x.category_id,
    name: x.category_name,
  };
}

export interface NewExpenseProps {
  newExpense: boolean;
  setNewExpense: Dispatch<SetStateAction<boolean>>;
}
